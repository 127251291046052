// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import Styled from './styles/Title.styles';

const Title = ({
  imageUrl, position, centered, text,
}) => (
  <Styled.Container imageUrl={imageUrl} position={position}>
    <Col sm={12} className="d-flex">
      <Styled.Text centered={centered} className="animated fadeIn">{text}</Styled.Text>
    </Col>
  </Styled.Container>
);

Title.defaultProps = {
  position: undefined,
  centered: false,
};

Title.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  position: PropTypes.bool,
  centered: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default Title;
