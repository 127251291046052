// @ts-nocheck
import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import { Col } from 'react-bootstrap';

import Styled from './styles/Footer.styles';

const Footer = () => {
  const intl = useIntl();
  return (
    <Styled.Container>
      <Col lg={12} className="mx-auto text-center d-flex justify-content-center">
        <Styled.Button to="/privacy-policy">
          {intl.formatMessage({ id: 'footer.privacy' })}
        </Styled.Button>
        <Styled.Button to="/terms-and-conditions">
          {intl.formatMessage({ id: 'footer.terms' })}
        </Styled.Button>
      </Col>
    </Styled.Container>
  );
};

export default injectIntl(Footer);
