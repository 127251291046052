// @ts-nocheck
import styled from 'styled-components';
import { Row } from 'react-bootstrap';

const Container = styled(Row)`
  background: url(${({ imageUrl }) => imageUrl}) rgba(0, 0, 0, 0.4);
  background-position: ${({ position }) => (position || 'center')};
  background-blend-mode: multiply;
  background-size: cover;
  height: 250px;
  @media (min-width: 992px) {
    height: 350px;
  }
`;

const Text = styled.h1`
  color: white;
  margin: 0 auto 0 1em;
  display: flex;
  font-weight: bold;
  animation-delay: 0.2s;
  letter-spacing: 2px;
  flex-direction: column;
  justify-content: center;
  margin: ${({ centered }) => centered && 'auto'};
`;

export default {
  Text,
  Container,
};
