// @ts-nocheck

import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import Styled from './styles/Layout.styles';
import Header from './Header';
import './styles/global.css';

const Layout = ({ children }) => {
  const [isIE, setIsIE] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsIE(/* @cc_on!@ */ false || !!document.documentMode);
      if (isIE) {
        // eslint-disable-next-line global-require
        const flexibility = require('flexibility');
        flexibility(document.body);
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap"
          rel="stylesheet"
        />
        <script
          defer
          src="https://use.fontawesome.com/releases/v5.8.2/js/all.js"
          integrity="sha384-DJ25uNYET2XCl5ZF++U8eNxPWqcKohUUBUpKGlNLMchM7q4Wjg2CUpjHLaL8yYPH"
          crossOrigin="anonymous"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/flexibility/2.0.1/flexibility.js"
          integrity="sha256-ETXu4iIohKzvSucuk6Bv0DCdqiqaTTGeMsjZ72szPzM="
          crossOrigin="anonymous"
        />
      </Helmet>
      <Styled.Wrapper className="container-fluid">
        <Header />
        {children}
      </Styled.Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
