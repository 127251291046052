import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import { Link } from 'gatsby-plugin-intl';

const Container = styled(Row)`
  box-shadow: 0px 1px 11px 1px #cecece;
  padding: 2em;
  margin-top: 3em;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(Link)`
  color: #004165;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #004165;
  padding: 0.5em 1em;
  margin-right: 1em;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #004165;
    color: white;
    text-decoration: none;
  }
`;

export default {
  Container,
  Button,
};
