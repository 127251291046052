import styled from 'styled-components';

const Wrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0px;
  }
`;

export default {
  Wrapper,
};
