import styled, { css } from 'styled-components';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'gatsby-plugin-intl';

const LinkStyling = css`
  &&.nav-link {
    color: black;
  }
  @media (min-width: 992px) {
    &&.nav-link {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      padding-right: 1em;
      margin: auto;
      font-weight: bold;
      font-size: 0.88em;
    }
    &&.nav-link:after {
      border-bottom: 1px solid white;
      display: block;
      content: "";
      margin: 0 auto;
      width: 2em;
      padding-top: 0.5em;
    }
    &&.nav-link.active:not(.button):after {
      border-bottom: 1px solid black;
    }
    &&.button {
      padding-right: 1em;
      margin-right: 1em;
      letter-spacing: 1.5px;
      border: 1px solid black;
      margin-bottom 0.5em;
      padding-left: 1em;
    }
    &&.button:after {
      padding-top: 0em;
    }
  }
`;

const Wrapper = styled.header`
  & .navbar-collapse.collapse {
  }
  @media (min-width: 900px) {
    width: 95%;
    margin: auto;
  }
`;

const NavLink = styled(Link)`
  ${LinkStyling}
`;

const ExternalNavLink = styled.a`
  ${LinkStyling}
`;

const NavDropDownTitle = styled.p`
  font-weight: bold;
  padding-left: 0.5em;
  margin-bottom: 0px;
`;

const NavDropDownLink = styled(Link)`
  padding-left: 0.5em;
  font-size: 0.9em;
  padding: 0.1em 0.5em;
`;

const NavDropDownContainer = styled.div`
  & {
    margin-bottom: 1em;
  }
  &:last-child {
    margin-bottom: 0em;
  }
`;

const NavDropDown = styled(NavDropdown)`
  & .dropdown-menu {
    border-radius: 0px;
  }
  & .all-locations {
    font-weight: bold;
    margin-bottom: 1em;
  }
  & svg {
    float: right;
  }
  && .nav-link:not(.dropdown-item) {
    color: black;
  }
  @media (min-width: 992px) {
    && {
      margin-top: 1px;
    }
    & .all-locations {
      font-weight: bold;
      margin-bottom: 1em;
    }
    && .nav-link:not(.dropdown-item) {
      color: black;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      padding-right: 1em;
      margin: auto;
      font-weight: bold;
      font-size: 0.88em;
    }
    & .dropdown-menu {
      left: -50%;
      min-width: 19rem;
      padding: 1em 1em;
      box-shadow: 0px 4px 4px 0px #00000030;
    }
  }
`;

export default {
  LinkStyling,
  Wrapper,
  NavLink,
  ExternalNavLink,
  NavDropDownTitle,
  NavDropDownLink,
  NavDropDownContainer,
  NavDropDown,
};
