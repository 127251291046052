// @ts-nocheck
import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import { Navbar, Nav, Image } from 'react-bootstrap';

import Styled from './styles/Header.styles';

const Header = () => {
  const intl = useIntl();
  return (
    <Styled.Wrapper>
      <Navbar collapseOnSelect expand="lg" variant="light">
        <Navbar.Brand>
          <Image
            fluid
            alt="MyStay English"
            src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,h_75,q_100/v1534983233/Logos/MyStay-English-logo-FINAL.jpg"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Styled.NavLink className="nav-link" to="/" activeClassName="active">
              {intl.formatMessage({ id: 'navigation.home' })}
            </Styled.NavLink>
            <Styled.NavLink className="nav-link" to="/program-and-pricing" activeClassName="active">
              {intl.formatMessage({ id: 'navigation.program' })}
            </Styled.NavLink>
            <Styled.NavLink className="nav-link" to="/faq" activeClassName="active">
              FAQ
            </Styled.NavLink>
            <Styled.NavLink
              className="nav-link"
              to="/contact-us"
              activeClassName="active"
            >
              Contact Us
            </Styled.NavLink>
            <Styled.NavLink
              className="nav-link button"
              to="/apply"
            >
              Apply Now
            </Styled.NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styled.Wrapper>
  );
};

export default injectIntl(Header);
